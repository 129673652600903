import { default as _91_46_46_46cms_93o7cdTC1kcdMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93M7m4th9R75Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexBrnMIfIei5Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93NKI4uBlqoUMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/akcii/[...slug].vue?macro=true";
import { default as indexIuT77GpikmMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93aIaoG4OqR2Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/articles/[...slug].vue?macro=true";
import { default as indexFONcy34gYlMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/articles/index.vue?macro=true";
import { default as indexFqgw9GNXaVMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/authorization/index.vue?macro=true";
import { default as index8pyNmu05QdMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/cart/index.vue?macro=true";
import { default as indexnyIdzeGzSsMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93cclPcHXU80Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalog/[...slug].vue?macro=true";
import { default as indexn7xfAzI3MHMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93epBomN5vSCMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultXlnkD0KXGFMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalogsearch/result.vue?macro=true";
import { default as _91_46_46_46slug_93foW8jKoTeWMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93TNdayJyWfmMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/fabriki/[...slug].vue?macro=true";
import { default as index9QHv06Xn10Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93Olucs2TiXJMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/product/[...slug].vue?macro=true";
import { default as indexgQmMkDadp9Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/profile/index.vue?macro=true";
import { default as index1rHfnQMm0zMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/profile/orders/index.vue?macro=true";
import { default as indexaEZAKpUrCPMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93qBmUiMucgEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/sets/[...slug].vue?macro=true";
import { default as index9xhtl0Xu2VMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93o7cdTC1kcdMeta?.name ?? "cms",
    path: _91_46_46_46cms_93o7cdTC1kcdMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93o7cdTC1kcdMeta || {},
    alias: _91_46_46_46cms_93o7cdTC1kcdMeta?.alias || [],
    redirect: _91_46_46_46cms_93o7cdTC1kcdMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93M7m4th9R75Meta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93M7m4th9R75Meta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93M7m4th9R75Meta || {},
    alias: _91_46_46_46slug_93M7m4th9R75Meta?.alias || [],
    redirect: _91_46_46_46slug_93M7m4th9R75Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexBrnMIfIei5Meta?.name ?? "3d-projects",
    path: indexBrnMIfIei5Meta?.path ?? "/3d-projects",
    meta: indexBrnMIfIei5Meta || {},
    alias: indexBrnMIfIei5Meta?.alias || [],
    redirect: indexBrnMIfIei5Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93NKI4uBlqoUMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93NKI4uBlqoUMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93NKI4uBlqoUMeta || {},
    alias: _91_46_46_46slug_93NKI4uBlqoUMeta?.alias || [],
    redirect: _91_46_46_46slug_93NKI4uBlqoUMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexIuT77GpikmMeta?.name ?? "akcii",
    path: indexIuT77GpikmMeta?.path ?? "/akcii",
    meta: indexIuT77GpikmMeta || {},
    alias: indexIuT77GpikmMeta?.alias || [],
    redirect: indexIuT77GpikmMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93aIaoG4OqR2Meta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93aIaoG4OqR2Meta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93aIaoG4OqR2Meta || {},
    alias: _91_46_46_46slug_93aIaoG4OqR2Meta?.alias || [],
    redirect: _91_46_46_46slug_93aIaoG4OqR2Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexFONcy34gYlMeta?.name ?? "articles",
    path: indexFONcy34gYlMeta?.path ?? "/articles",
    meta: indexFONcy34gYlMeta || {},
    alias: indexFONcy34gYlMeta?.alias || [],
    redirect: indexFONcy34gYlMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexFqgw9GNXaVMeta?.name ?? "authorization",
    path: indexFqgw9GNXaVMeta?.path ?? "/authorization",
    meta: indexFqgw9GNXaVMeta || {},
    alias: indexFqgw9GNXaVMeta?.alias || [],
    redirect: indexFqgw9GNXaVMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: index8pyNmu05QdMeta?.name ?? "cart",
    path: index8pyNmu05QdMeta?.path ?? "/cart",
    meta: index8pyNmu05QdMeta || {},
    alias: index8pyNmu05QdMeta?.alias || [],
    redirect: index8pyNmu05QdMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexnyIdzeGzSsMeta?.name ?? "cart-success",
    path: indexnyIdzeGzSsMeta?.path ?? "/cart/success",
    meta: indexnyIdzeGzSsMeta || {},
    alias: indexnyIdzeGzSsMeta?.alias || [],
    redirect: indexnyIdzeGzSsMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93cclPcHXU80Meta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93cclPcHXU80Meta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93cclPcHXU80Meta || {},
    alias: _91_46_46_46slug_93cclPcHXU80Meta?.alias || [],
    redirect: _91_46_46_46slug_93cclPcHXU80Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexn7xfAzI3MHMeta?.name ?? "catalog",
    path: indexn7xfAzI3MHMeta?.path ?? "/catalog",
    meta: indexn7xfAzI3MHMeta || {},
    alias: indexn7xfAzI3MHMeta?.alias || [],
    redirect: indexn7xfAzI3MHMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93epBomN5vSCMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93epBomN5vSCMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93epBomN5vSCMeta || {},
    alias: _91_46_46_46slug_93epBomN5vSCMeta?.alias || [],
    redirect: _91_46_46_46slug_93epBomN5vSCMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultXlnkD0KXGFMeta?.name ?? "catalogsearch-result",
    path: resultXlnkD0KXGFMeta?.path ?? "/catalogsearch/result",
    meta: resultXlnkD0KXGFMeta || {},
    alias: resultXlnkD0KXGFMeta?.alias || [],
    redirect: resultXlnkD0KXGFMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93foW8jKoTeWMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93foW8jKoTeWMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93foW8jKoTeWMeta || {},
    alias: _91_46_46_46slug_93foW8jKoTeWMeta?.alias || [],
    redirect: _91_46_46_46slug_93foW8jKoTeWMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TNdayJyWfmMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93TNdayJyWfmMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93TNdayJyWfmMeta || {},
    alias: _91_46_46_46slug_93TNdayJyWfmMeta?.alias || [],
    redirect: _91_46_46_46slug_93TNdayJyWfmMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index9QHv06Xn10Meta?.name ?? "fabriki",
    path: index9QHv06Xn10Meta?.path ?? "/fabriki",
    meta: index9QHv06Xn10Meta || {},
    alias: index9QHv06Xn10Meta?.alias || [],
    redirect: index9QHv06Xn10Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Olucs2TiXJMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93Olucs2TiXJMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93Olucs2TiXJMeta || {},
    alias: _91_46_46_46slug_93Olucs2TiXJMeta?.alias || [],
    redirect: _91_46_46_46slug_93Olucs2TiXJMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexgQmMkDadp9Meta?.name ?? "profile",
    path: indexgQmMkDadp9Meta?.path ?? "/profile",
    meta: indexgQmMkDadp9Meta || {},
    alias: indexgQmMkDadp9Meta?.alias || [],
    redirect: indexgQmMkDadp9Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index1rHfnQMm0zMeta?.name ?? "profile-orders",
    path: index1rHfnQMm0zMeta?.path ?? "/profile/orders",
    meta: index1rHfnQMm0zMeta || {},
    alias: index1rHfnQMm0zMeta?.alias || [],
    redirect: index1rHfnQMm0zMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexaEZAKpUrCPMeta?.name ?? "recently",
    path: indexaEZAKpUrCPMeta?.path ?? "/recently",
    meta: indexaEZAKpUrCPMeta || {},
    alias: indexaEZAKpUrCPMeta?.alias || [],
    redirect: indexaEZAKpUrCPMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93qBmUiMucgEMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93qBmUiMucgEMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93qBmUiMucgEMeta || {},
    alias: _91_46_46_46slug_93qBmUiMucgEMeta?.alias || [],
    redirect: _91_46_46_46slug_93qBmUiMucgEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index9xhtl0Xu2VMeta?.name ?? "wishlist",
    path: index9xhtl0Xu2VMeta?.path ?? "/wishlist",
    meta: index9xhtl0Xu2VMeta || {},
    alias: index9xhtl0Xu2VMeta?.alias || [],
    redirect: index9xhtl0Xu2VMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241009143355/pages/wishlist/index.vue").then(m => m.default || m)
  }
]